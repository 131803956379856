<template>
  <div class="card card-custom">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-3"
        id="kt_wizard_v3"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav -->
        <div class="wizard-nav border-bottom mb-1 mb-lg-5">
          <div class="wizard-steps px-8 py-8 px-lg-15 py-lg-3">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-label">
                <h3 class="wizard-title">Buyurtma malumotlari</h3>
                <div class="wizard-bar"></div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <h3 class="wizard-title">TTN malumotlari</h3>
                <div class="wizard-bar"></div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body-->

        <div class="row justify-content-center px-8 px-lg-10">
          <div class="col-12">
            <div v-if="loading" class="text-primary text-center my-5">
              <b-spinner
                style="width: 3rem; height: 3rem"
                label="Large Spinner"
              ></b-spinner>
            </div>
            <!--begin: Wizard Form-->
            <form v-if="!loading" class="form" id="kt_form">
              <div
                data-wizard-type="step-content"
                data-wizard-state="current"
                class="pb-5"
              >
                <div class="row flex-wrap">
                  <div class="col-4">
                    <div class="form-group">
                      <label>Nomi</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        disabled
                        :value="list.zayavka_owner"
                      />
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <label>Zayavka Status</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        disabled
                        :value="list.zayavka_status_name"
                      />
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="form-group">
                      <label>Postavshik</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        disabled
                        :value="
                          list.deliver == 'POS' ? 'Boshqalar' : data.deliver
                        "
                      />
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="form-group">
                      <label>Narxi</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        disabled
                        :value="list.price.toLocaleString('zh-ZH')"
                      />
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <label>NDS summa</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        disabled
                        :value="list.nds_summa.toLocaleString('zh-ZH')"
                      />
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="form-group">
                      <label>NDS bilan Narxi</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        disabled
                        :value="list.price_with_nds"
                      />
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="form-group">
                      <label>Yuk ketish sanasi</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        disabled
                        :value="list.start_date.slice(0, 10)"
                      />
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="form-group">
                      <label>Yuk yetib borish sanasi</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        disabled
                        :value="list.end_date.slice(0, 10)"
                      />
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="form-group" v-if="list.delivered_date !== null">
                      <label>Yetqazib berilgan sana</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        disabled
                        :value="list.delivered_date.slice(0, 10)"
                      />
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <label>Kg</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        disabled
                        :value="list.post_kg"
                      />
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <label>m3</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        disabled
                        :value="list.post_m3"
                      />
                    </div>
                  </div>
                </div>
                <!-- Driver info -->
                <div v-if="list.deliver == 'BTS' || list.truck.driver == null">
                  <h4 class="text-dark mb-5">Haydovchi malumotlari</h4>
                  <div class="row flex-wrap">
                    <div class="col-6">
                      <div
                        class="form-group"
                        v-if="list.main_driver_description !== null"
                      >
                        <label>Asosiy Haydovchi</label>
                        <input
                          disabled
                          type="text"
                          class="
                            form-control form-control-solid form-control-lg
                          "
                          :value="
                            list.main_driver_description.staff_description
                              .staffpersonal_description.last_name +
                            ' ' +
                            list.main_driver_description.staff_description
                              .staffpersonal_description.first_name +
                            ' ' +
                            list.main_driver_description.staff_description
                              .staffpersonal_description.father_name
                          "
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div
                        class="form-group"
                        v-if="list.assistant_driver !== null"
                      >
                        <label>Yordamchi</label>
                        <input
                          disabled
                          type="text"
                          class="
                            form-control form-control-solid form-control-lg
                          "
                          :value="list.assistant_driver"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="form-group">
                    <label>Izoh</label>
                    <input
                      type="text"
                      class="form-control form-control-solid form-control-lg"
                      disabled
                      :value="list.comment"
                    />
                  </div>
                </div>

                <!-- Provider Info -->
                <div v-if="list.deliver == 'POS'">
                  <h4 class="text-dark mb-5">Postavshik malumotlari</h4>
                  <div class="row flex-wrap">
                    <div class="col-4" v-if="list.postavshik_driver !== null">
                      <div class="form-group">
                        <label> Haydovchi</label>
                        <input
                          disabled
                          type="text"
                          class="
                            form-control form-control-solid form-control-lg
                          "
                          :value="list.postavshik_driver"
                        />
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form-group">
                        <label>Narxi</label>
                        <input
                          disabled
                          type="text"
                          class="
                            form-control form-control-solid form-control-lg
                          "
                          :value="list.postavshik_price | mask"
                        />
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form-group">
                        <label>Nds(Summa)</label>
                        <input
                          disabled
                          type="text"
                          class="
                            form-control form-control-solid form-control-lg
                          "
                          :value="list.postavshik_nds_summa | mask"
                        />
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form-group">
                        <label>Narx (Nds blan)</label>
                        <input
                          disabled
                          type="text"
                          class="
                            form-control form-control-solid form-control-lg
                          "
                          :value="list.postavshik_price_with_nds | mask"
                        />
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form-group">
                        <label>Mashina raqami</label>
                        <input
                          disabled
                          type="text"
                          class="
                            form-control form-control-solid form-control-lg
                          "
                          :value="list.postavshik_truck_info"
                        />
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form-group">
                        <label>Buyurtma masofasi (zayavka_distance)</label>
                        <input
                          disabled
                          type="text"
                          class="
                            form-control form-control-solid form-control-lg
                          "
                          :value="list.zayavka_distance | mask"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <div class="card-toolbar">
                        <div
                          type="button"
                          @click="getContract"
                          class="btn btn-primary font-weight-bolder"
                        >
                          <span class="svg-icon svg-icon-white"
                            ><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2020-09-15-014444/theme/html/demo1/dist/../src/media/svg/icons/Communication/Send.svg--><svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                              >
                                <rect x="0" y="0" width="24" height="24" />
                                <path
                                  d="M3,13.5 L19,12 L3,10.5 L3,3.7732928 C3,3.70255344 3.01501031,3.63261921 3.04403925,3.56811047 C3.15735832,3.3162903 3.45336217,3.20401298 3.70518234,3.31733205 L21.9867539,11.5440392 C22.098181,11.5941815 22.1873901,11.6833905 22.2375323,11.7948177 C22.3508514,12.0466378 22.2385741,12.3426417 21.9867539,12.4559608 L3.70518234,20.6826679 C3.64067359,20.7116969 3.57073936,20.7267072 3.5,20.7267072 C3.22385763,20.7267072 3,20.5028496 3,20.2267072 L3,13.5 Z"
                                  fill="#000000"
                                />
                              </g></svg
                            ><!--end::Svg Icon--></span
                          >

                          Shartnoma
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="pb-5" data-wizard-type="step-content">
                <div class="row flex-wrap">
                  <div class="col-4">
                    <div class="form-group">
                      <label>Mijoz nomi</label>
                      <input
                        disabled
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        :value="list.zayavka_ttn_desctiption.client_name"
                      />
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="form-group">
                      <label>Shartnoma raqami</label>
                      <input
                        disabled
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        :value="list.zayavka_ttn_desctiption.contract_number"
                      />
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="form-group">
                      <label>Reg Raqami</label>
                      <input
                        disabled
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        :value="getRegNum"
                      />
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <label>Brand name/model</label>
                      <input
                        disabled
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        :value="getNameModel"
                      />
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="form-group">
                      <label>TTN reg raqami</label>
                      <input
                        disabled
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        :value="list.zayavka_ttn_desctiption.reg_number"
                      />
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="form-group">
                      <label>Kiritilgan</label>
                      <input
                        disabled
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        :value="list.zayavka_ttn_desctiption.created_at"
                      />
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <label>Yangilangan</label>
                      <input
                        disabled
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        :value="list.zayavka_ttn_desctiption.updated_at"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <div class="card-toolbar">
                        <a
                          @click="getExcel(getCurrTnnId.zayavka)"
                          class="btn btn-primary font-weight-bolder"
                        >
                          <span class="svg-icon svg-icon-white"
                            ><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2020-09-15-014444/theme/html/demo1/dist/../src/media/svg/icons/Communication/Send.svg--><svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                              >
                                <rect x="0" y="0" width="24" height="24" />
                                <path
                                  d="M3,13.5 L19,12 L3,10.5 L3,3.7732928 C3,3.70255344 3.01501031,3.63261921 3.04403925,3.56811047 C3.15735832,3.3162903 3.45336217,3.20401298 3.70518234,3.31733205 L21.9867539,11.5440392 C22.098181,11.5941815 22.1873901,11.6833905 22.2375323,11.7948177 C22.3508514,12.0466378 22.2385741,12.3426417 21.9867539,12.4559608 L3.70518234,20.6826679 C3.64067359,20.7116969 3.57073936,20.7267072 3.5,20.7267072 C3.22385763,20.7267072 3,20.5028496 3,20.2267072 L3,13.5 Z"
                                  fill="#000000"
                                />
                              </g></svg
                            ><!--end::Svg Icon--></span
                          >

                          Export EXCEL</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <!--end: Wizard Form-->
          </div>
        </div>
        <!--end: Wizard Body-->
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>

<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-3.scss';
</style>

<script>
import axios from 'axios'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import KTWizard from '@/assets/js/components/wizard'

export default {
  name: 'Wizard-3',
  data() {
    return {
      loading: true,
      list: {},
      data: {},
      contractid: ''
    }
  },
  filters: {
    // mask: function(val) {
    //   return val.toLocaleString('zh-ZH')
    // }
  },
  watch: {
    data(val) {
      this.list = val
      this.loading = false
    },

    loading(val) {
      if (!val) {
        this.initWizard()
      }
    }
  },
  computed: {
    getCurrTnnId() {
      return this.$store.state.requests.alltnnid
    },
    getRegNum() {
      if (this.data.truck !== null && this.data.truck !== undefined) {
        return (
          this.data.truck.reg_number_region + ' ' + this.data.truck.reg_number
        )
      } else return "Malumot yo'q"
    },
    getNameModel() {
      if (
        // eslint-disable-next-line no-extra-boolean-cast
        Boolean(this.data.truck)
      ) {
        if (this.data.truck.truck_type !== undefined) {
          const data =
            this.data.truck.truck_type.brand.name +
            ' ' +
            this.data.truck.truck_type.model +
            " (yuk ko'tarish: " +
            this.data.truck.truck_type.capacity_kg +
            ' kg' +
            ' hajmi: ' +
            this.data.truck.truck_type.capacity_m3 +
            ' m3)'
          return data
        } else {
          return "Malumot yo'q"
        }
      } else {
        return "Malumot yo'q"
      }
    }
  },
  async mounted() {
    await this.$store.dispatch('getClientPageZayavka')
    this.data =
      await this.$store.state.clientStore.clientPageZayavka.results.find(
        (x) => x.id == this.$route.params.id
      )
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Buyurtma malumotlari' }])

    this.initWizard()
  },
  methods: {
    initWizard() {
      this.$nextTick(() => {
        // Initialize form wizard
        const wizard = new KTWizard('kt_wizard_v3', {
          startStep: 1, // initial active step number
          clickableSteps: true // allow step clicking
        })

        // Validation before going to next page
        wizard.on('beforeNext', function (/*wizardObj*/) {
          // validate the form and use below function to stop the wizard's step
          // wizardObj.stop();
        })
      })
    },
    getExcel(val) {
      axios
        .get('zayavkas/ttn/reporttoexcel/' + val + '/', {
          responseType: 'blob'
        })
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel'
            })
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'TTN')
          document.body.appendChild(link)
          link.click()
        })
    },
    getContract() {
      this.$router.replace(
        {
          path: `/clientcontractdetails/${this.list.contract}`
        },
        () => {
          this.$router.go(0)
        }
      )
    }
  }
}
</script>
<style scoped>
.wizard-step {
  padding: 0;
}
</style>
